import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import { FaLinkedin, FaGithub } from "react-icons/fa"
// import hypno from "../assets/images/hypnotize.png"
// import ConvinceToAction from "../components/convincetoaction"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Link from "../components/link"
// import loadable from '@loadable/component'
import DynamicBanner from '../components/DynamicBanner'
import QuoteForm from '../components/QuoteFormAlt'

import headshot from "../assets/images/headshot.jpg"
const primary = "#26a27b"

// const Background = styled.div`
//   background: linear-gradient(150deg, #147452 5%, #26a27b 92.13%, #3bb18c 100%);
//   color: white;
//   padding: 8rem 0;
//   clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
//   text-align: center;
// `
const Hr = styled.hr`
  width: 100%;
  border-radius: 0.5rem;
  border-width: 0.1rem;
  background: ${(props) => props.theme.primary};
`

const useStyles = makeStyles((theme) => ({
  missionStatement: {
    fontStyle: "italic",
    fontWeight: 300,
    fontSize: "1.5rem",
    maxWidth: "50em",
    lineHeight: 1.4,
  },
  rowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
  },
  image: {
    width: 128,
    height: 150,
    // backgroundColor: `${primary}`,
  },
  img: {
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "visible",
  },
  green: {
    // color: "#e1f4ef",
    // backgroundColor: "#147452",
    width: theme.spacing(14),
    height: theme.spacing(14),
    border: "10px solid #e1f4ef",
  },
  chip: {
    marginLeft: 2.5,
    marginRight: 2.5,
    backgroundColor: `${primary}`,
    color: "white",
    borderRadius: "5px",
    height: "28px",
    marginTop: 5,
  },
}))


let About = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Layout>
      <Seo
        title="About Us"
        description="We design and develop modern websites at affordable prices for small businesses and startups out of Southeast Michigan. Located out of Ann Arbor "
      />
      {/* <Background>
        <h1>About Snappy Web Design</h1>
      </Background> */}
      <DynamicBanner header={'About Snappy Web Design'} align='center' />
      <Grid container direction="column">
        <Grid
          item
          container
          justify="center"
          className={classes.rowContainer}
          // style={{ marginTop: "3em" }}
        >
          <h4
            className="text-center"
            style={{
              fontStyle: "italic",
              fontWeight: 300,
              fontSize: "1.25rem",
              maxWidth: "50em",
              lineHeight: 1.5,
            }}
          >
            In the new digital age of business, functional and beautiful
            websites aren't a luxury, but a requirement. For most, the steep
            prices of custom websites warrant them unrealistic. Snappy Web
            Design is disrupting this reality by providing fast, modern,
            aesthetic, and inexpensive websites to small businesses owners,
            organizations, and creatives in the Southeast Michigan region.
          </h4>
        </Grid>
        <Grid
          item
          container
          className={classes.rowContainer}
          direction={matchesMD ? "column" : "row"}
          alignItems={matchesMD ? "center" : undefined}
          justify="space-around"
          style={{ marginTop: "10em", marginBottom: "10em" }}
        >
          <Grid>
            <Grid
              item
              container
              direction="column"
              lg
              style={{ maxWidth: "35em" }}
            >
              <Grid item>
                <Typography
                  align={matchesMD ? "center" : undefined}
                  variant="h4"
                  gutterBottom
                >
                  History
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  align={matchesMD ? "center" : undefined}
                  paragraph
                  style={{ fontWeight: "700", fontStyle: "italic" }}
                >
                  We're the new kid on the block
                </Typography>
                <Typography
                  variant="body1"
                  align={matchesMD ? "center" : undefined}
                  paragraph
                >
                  Founded in May of 2021, we're ready to revolutionalize the
                  websites of small business owners in the Southeast Michigan
                  region.
                </Typography>
                <Typography
                  variant="body1"
                  align={matchesMD ? "center" : undefined}
                  paragraph
                >
                  It all started with a question: Why aren't the same
                  technologies that the largest companies in the world use
                  available to small businesses? Some freelancers are capable of
                  implementing these technologies, but opt for legacy frameworks
                  due to their easier implementations into systems like Drupal,
                  Wordpress, and 2010-era hosting solutions.
                </Typography>
                <Typography
                  variant="body1"
                  align={matchesMD ? "center" : undefined}
                  paragraph
                >
                  Fortunately, recent technological advancements have paved the
                  way for new hosting providers that provide truly innovative
                  solutions like automatic integrations and self-contained
                  deployment. That means, integrating the fastest technologies
                  is now an affordable and viable option for everyone.
                </Typography>
                <Typography
                  variant="body1"
                  align={matchesMD ? "center" : undefined}
                  paragraph
                >
                  Snappy Web Design leverages the same technologies that powers
                  websites like Netflix, Spotify, Hulu, Paypal, and can do the
                  same for you at unbelievably low prices.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            // justify="center"
            justify={matchesXS ? "center" : matchesMD ? "center" : "flex-end"}
            alignItems="center"
            // className="text-center"
            lg
          >
            <Grid item>
              <StaticImage
                src="../assets/images/building.svg"
                alt="office building"
                placeholder="tracedSVG"
                layout="constrained"
                height={400}
                style={{
                  display: "block",
                  maxHeight: matchesMD ? 200 : "25em",
                  marginTop: matchesXS ? 50 : undefined,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <h4 style={{ color: `${primary}` }} className="text-center mb-4">
            Team
          </h4>
        </Grid>
        <Grid item container justify="center" className={classes.rowContainer}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Paper
              className={classes.paper}
              style={{ borderRadius: "6px" }}
              elevation={3}
            >
              <Grid
                container
                spacing={matchesXS ? 0 : 2}
                justify={matchesXS ? "center" : undefined}
              >
                <Grid item>
                  <ButtonBase className={classes.image} disableRipple>
                    <Avatar
                      src={headshot}
                      alt="Professional headshot"
                      className={classes.green}
                    >
                      J
                    </Avatar>
                  </ButtonBase>
                </Grid>

                <Grid
                  item
                  xs={10}
                  lg={10}
                  sm
                  container
                  justify={matchesXS ? "center" : undefined}
                >
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    alignItems={matchesXS ? "center" : undefined}
                    spacing={2}
                  >
                    <Grid item xs>
                      <Typography
                        variant="h6"
                        style={{ marginTop: "1em", color: `${primary}` }}
                        gutterBottom
                      >
                        Joe S.
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 500, fontSize: ".95rem" }}
                        gutterBottom
                      >
                        Web Developer & Accounting Student
                      </Typography>
                      <Grid
                        item
                        container
                        direction="row"
                        className="mt-1"
                        spacing={1}
                      >
                        <Grid item>
                          <Link
                            to="https://www.linkedin.com/in/snappywebdesign/"
                            title="Snappy Web Design on LinkedIn"
                          >
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={
                                <FaLinkedin
                                  style={{
                                    width: 15,
                                    height: 15,
                                    verticalAlign: "baseline",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                              label="LinkedIn"
                            />
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link
                            to="https://github.com/SnappyWebDesign"
                            title="Snappy Web Design on Github"
                          >
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={
                                <FaGithub
                                  style={{
                                    width: 15,
                                    height: 15,
                                    verticalAlign: "baseline",
                                    marginLeft: 5,
                                  }}
                                />
                              }
                              label="Github"
                            />
                          </Link>
                        </Grid>
                      </Grid>
                      <Hr />
                      <Grid item className="mt-3">
                        <h6>Bio</h6>
                        <p className="text-muted">joe@snappywebdesign.net</p>
                      </Grid>
                      <Grid item className="my-1">
                        <Typography variant="body1">
                          I first began learning how to code in fifth grade as a
                          hobby interest. I returned to coding in 2019 with the
                          goal of developing a skillset that would aid me in
                          designing & creating solutions to unique problems that
                          could arise in my career, or minor daily
                          inconveniences, like tracking expenses. After quickly
                          realizing that applications without elegant user
                          interfaces are near impossible for external users to
                          navigate, I set out to re-learn web design.
                        </Typography>
                        <Typography variant="body1" className="mt-2 mb-2">
                          I honed my skills by creating websites for friends and
                          various passion projects. Now, I'm ready to help local
                          business owners, organizations, and creative
                          individuals deliver seamless digital experiences that
                          users are growing to expect in the new digital age.
                        </Typography>
                      </Grid>
                      <Grid item className="py-2">
                        <Chip label="HTML" className={classes.chip} />
                        <Chip label="CSS" className={classes.chip} />
                        <Chip label="Javascript" className={classes.chip} />
                        <Chip label="React.js" className={classes.chip} />
                        <Chip label="Node.js" className={classes.chip} />
                        <Chip label="Gatsby.js" className={classes.chip} />
                        <Chip label="Next.js" className={classes.chip} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container justify="center" className={classes.rowContainer}>
          <Grid
            item
            container
            className="py-5"
            style={{ marginBottom: "10rem" }}
          ></Grid>
        </Grid>
        {/* <ConvinceToAction bgimage={hypno} /> */}
        <QuoteForm />
      </Grid>
      {matchesXS ? <div className="py-3" /> : ""}
    </Layout>
  )
}

export default About
