import React from "react"
import PropTypes from 'prop-types'

import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  bannerCtr: {
    backgroundColor: "#15684b",
    padding: "5rem",
    marginBottom: "3.5rem",
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem',
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem .5rem",
    },
  },
  h1: {
    fontSize: "2.5rem",
    textTransform: "uppercase",
    paddingBottom: "1rem",
    fontWeight: 700,
    color: "#fff",
    fontFamily: "'Source Sans Pro', sans-serif",
  },
  p: {
    color: "#fff",
    fontSize: "1.15rem",
  },
}))

export default function NewContact({ header, subheader, align }) {
  const classes = useStyles()

  return (
    <>
      <Container maxWidth="lg" className={classes.bannerCtr}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                align={align === "center" && "center"}
                className={classes.h1}
              >
                {header}
              </Typography>
              {subheader && (
                <Typography
                  variant="body1"
                  align={align === "center" && "center"}
                  className={classes.p}
                >
                  {subheader}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  )
}

NewContact.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
}